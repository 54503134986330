import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import './assets/css/reset.min.css'
import 'normalize.css/normalize.css'
import '@/assets/css/reset.css'
import '@/assets/css/index.scss'
import '@/assets/js/flexible.js'
// import 'lib-flexible'

const Vue = createApp(App)
Vue.config.warnHandler = (msg, instance, trace) => {}
// console.log(Vue); .use({mounted () {
//   document.dispatchEvent(new Event('render-event'))
// }})
Vue.use(store).use(router).use(ElementPlus).mount('#app')
