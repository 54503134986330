<template>
  <div :class="state">
    <div class="container">
        <!-- 左侧logo -->
      <a href="/home">
        <img src="../assets/img/logo.png" alt="" />
      </a>
      <!-- 右侧导航 -->
      <el-menu v-if="state==='pc'" class="menu_box" :default-active="activeIndex" mode="horizontal" text-color='#788193' active-text-color='#1667FF' router>
        <el-menu-item v-for="(item, index) in pageForm"
          :key="index + item.path" :index="item.path">
          <span>{{ item.name }}</span>
          <div v-if="activeIndex == item.path" class="bottom_line"></div>
        </el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
import { useRoute } from 'vue-router'
export default {
  setup() {
    const state = computed(() => useStore().state.client);
    const activeIndex = ref('home')
    const route = useRoute()
    watch(()=>route.path,()=>{
      activeIndex.value!==route.path.substring(1)?activeIndex.value=route.path.substring(1):null
    })
    const pageForm = ref([
      {
        name: "首页",
        path: "home",
      },
      {
        name: "绿能派",
        path: "greenEnergy",
      },
      {
        name: "绿能专家",
        path: "greenEnergyExpert",
      },
      {
        name: "关于我们",
        path: "about",
      }
    ])
    return {
      state,
      pageForm,
      activeIndex
    };
  },
};
</script>

<style lang='scss'>
.pc {
  .container {
    height: 100px;
    margin: 0 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    a{
      img {
        height: 50px;
      }
    }
    .el-menu{
        border: none;
        .el-menu-item {
          font-size: 21px;
        }
        .el-menu-item:hover {
          color: #1667FF !important;
        }
        .is-active{
          border-bottom: 0;
          border-bottom-color: #fff !important;
          color: #1667FF !important;
        }
      }
  }
  .el-menu--horizontal > .el-menu-item.is-active {
    border-width: 4px;
  }
  .menu_box {
    .is-active {
      position: relative;
      @keyframes lineRun {
        0% {
          width: 0;
        }
        25% {
          width: 5px;
        }
        50% {
          width: 15px;
        }
        75% {
          width: 20px;
        }
        100% {
          width: 23px;
        }
      }
      .bottom_line {
        position: absolute;
        left: 50%;
        bottom: -22px;
        width: 23px;
        height: 4px;
        transform: translate(-50%, -50%);
        // border-radius: 30px;
        background: #1667FF;
        transition: width 0.5s ease-out;;
        animation: lineRun;
        animation-duration: .3s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
      }
    }
  }
}
.mobile{
  .container{
    font-size: 0;
    box-sizing: border-box;
    padding: .2rem .1rem;
    a{
      img{
        width: 2.5rem;
      }
    }
  }
}
</style>