<template>
  <div class="fixed_box">
    <div class="flex_box">
      <div class="common">
        <div class="hover_content">
          <div class="code_box">
            <div class="code_content">
              <img
                class="image"
                referrerpolicy="no-referrer"
                src="../assets/img/wx_account.png"
              />
              <span class="item">绿能派公众号</span>
            </div>
            <!-- <div class="line"></div>
            <div class="code_content">
              <img
                class="image"
                referrerpolicy="no-referrer"
                src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng6b6eca1fbd5deee5c16dad0b9df70fe177ef6e4051c0d1d7d8426ab36e301a88"
              />
              <span class="item">绿能派小程序</span>
            </div> -->
          </div>
        </div>
        <img class="hover" src="../assets/img/ems_cnpl_hover.png" alt="绿能派-公众号" />
        <img
          class="default"
          src="../assets/img/ems_cnpl_default.png"
          alt="绿能派-公众号"
        />
      </div>
      <div class="common">
        <div class="hover_content mini_app">
          <div class="code_box">
            <!-- <div class="code_content">
              <img
                class="image"
                referrerpolicy="no-referrer"
                src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngb4d098004b5cd87078113208b4e3ddd10428d6ee5fa0c914c5d8701dc51b182c"
              />
              <span class="item app">扫码下载绿能派APP</span>
            </div> -->
            <div class="code_content">
              <img
                class="image"
                referrerpolicy="no-referrer"
                src="../assets/img/mini_app.png"
              />
              <span class="item">绿能派小程序</span>
            </div>
          </div>
        </div>
        <img class="hover" src="../assets/img/mini_app_hover.png" alt="绿能派-小程序" />
        <img
          class="default"
          src="../assets/img/mini_app_default.png"
          alt="绿能派-小程序"
        />
      </div>
      <el-backtop style="position: fixed;
        right: 0.208333rem;
        bottom: 0.833333rem;
        border-radius: 0;
        z-index: 200;" class="common" :bottom="160">
        <img class="hover" style="height: 54px; width: 54px;" src="../assets/img/back_top_hover.png" alt="返回顶部" />
        <img class="default" src="../assets/img/back_top_default.png" alt="返回顶部" />
      </el-backtop>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.fixed_box {
  position: fixed;
  right: 40px;
  bottom: 160px;
  z-index: 200;
}
.flex_box {
  width: 54px;
  height: 162px;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  .common {
    background: #1667ff;
    width: 54px;
    height: 54px;
    cursor: pointer;

    .hover_content {
      display: none;
    }
    .default {
      position: absolute;
      width: 54px;
      height: 54px;
    }
    .hover {
      display: none;
    }
  }
  .common:hover {
    .hover {
      display: block;
      width: 54px;
      height: 54px;
    }
    .hover_content {
      display: block;
      position: absolute;
      right: 80px;
    //   width: 256px;
      width: 128px;
      height: 142px;
      background: #ffffff;
      transition: all ease-in-out 0.3;
      padding: 16px;
      box-sizing: border-box;
      &:before {
        position: absolute;
        top: 18px;
        right: -16px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        border-width: 10px 5px 11px 13px;
        border-left-color: #fff;
        z-index: 9;
      }
      &:after {
        position: absolute;
        top: 18px;
        right: -16px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        border-width: 11px;
      }

      .code_box {
        display: grid;
        grid-template-columns: auto 32px auto;
        .line {
          position: relative;
          right: 16px;
          top: 16px;
          height: 62px;
          border-right: 1px solid #eeeeee;
        }
        .code_content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .image {
            width: 96px;
            height: 96px;
          }
          .item {
            font-size: 10px;
          }
          .app {
            font-size: 10px;
            transform: scale(0.7);
            width: 100px;
            white-space: nowrap;
            position: absolute;
            bottom: 16px;
          }
        }
      }
    }
    .mini_app {
      width: 128px;
    }
    .default {
      display: none;
    }
  }
}
</style>
