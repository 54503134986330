import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      title: '公司官网'
    },
    component: Home
  },
  {
    path: '/greenEnergy',
    name: 'greenEnergy',
    meta: {
      title: '绿能派'
    },
    component: ()=>import('../views/greenEnergy.vue')
  },
  {
    path: '/greenEnergyExpert',
    name: 'greenEnergyExpert',
    meta: {
      title: '绿能专家'
    },
    component: ()=>import('../views/greenEnergyExpert.vue')
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      title: '关于我们'
    },
    component: ()=>import('../views/about.vue')
  },
  {
    path: '/information',
    name: 'information',
    meta: {
      title: '互为资讯'
    },
    component: ()=>import('../views/information.vue')
  },
  {
    path: '/information_2',
    name: 'information_2',
    meta: {
      title: '互为资讯'
    },
    component: ()=>import('../views/information_2.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_ROUTER),
  routes,
  scrollBehavior() {
    // 始终滚动到顶部
    return { top: 0 }
  },
})
router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title == undefined? '公司官网' : to.meta.title
  next();
});

export default router
